html,
body,
#root {
  display: block;
  height: 100%;
  overflow-x: hidden;
  background-color: #f5faff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Current Month Dates**/
.nice-dates-day,
.nice-dates-day.-wide {
  color: #f37d2a !important;
}

/* Next Month Dates**/
.nice-dates-day.-outside {
  color: #999 !important;
}

/* Selected Dates**/
.nice-dates-day:before {
  background-color: #03acef !important;
}

.nice-dates-day:after {
  border: 1px solid #999999 !important;
}

/* disabled dates color*/
.nice-dates-day.-disabled {
  color: #999999 !important;
}

/* Time Picker Color Override*/
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background-color: #03acef !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #03acef !important;
}

.Mui-selected {
  color: #ffffff;
}

.MuiBottomNavigationAction-root.Mui-selected {
  color: #ffffff !important;
  background-color: #088cca !important;
}

@media only screen and (max-width: 375px) {
  .nice-dates-day_date {
    padding-top: 5px;
  }
  .MuiTypography-body2 {
    font-size: 0.8rem !important;
  }
}

.rbc-event {
  background-color: white !important;
  padding: 0 !important;
}

.rbc-event-label {
  display: none !important;
}
.rbc-day-slot .rbc-events-container {
  width: 100%;
}

.react-calendar {
  border: none !important;
  /* width: 100% !important; */
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding-bottom: 12px;
  border-radius: 0.3cm;
}

.react-calendar__tile {
  height: 50px;
  padding: 0.5em 0.5em !important;
  font-size: 15px;
}

.calSmall.react-calendar {
  width: 300px !important;
  display: table;
}

.calSmall .react-calendar__tile {
  height: 42px !important;
}

.calSmall .react-calendar__navigation__label {
  font-size: 18px !important;
}

@media only screen and (max-width: 450px) {
  .calSmall .react-calendar__navigation__label {
    font-size: 14px !important;
    padding: 0px;
  }
}

.rbc-toolbar button {
  color: inherit !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: black;
}

.react-calendar__navigation {
  background-color: #03acef;
  border-top-left-radius: 0.3cm;
  border-top-right-radius: 0.3cm;
  height: 50px !important;
}

.react-calendar__navigation__prev-button {
  color: white !important;
  font-size: 30px !important;
}

.react-calendar__navigation__next-button {
  color: white !important;
  font-size: 30px !important;
}

.react-calendar__navigation__prev2-button {
  border-top-left-radius: 0.3cm;
  color: white !important;
  font-size: 30px !important;
}

.react-calendar__navigation__next2-button {
  border-top-right-radius: 0.3cm;
  color: white !important;
  font-size: 30px !important;
}

.react-calendar__navigation__label {
  font-size: 20px !important;
  color: white !important;
}

/* .react-calendar__month-view  button{
  border: 1px solid rgb(255, 255, 255) !important;
} */

.fc-event,
.fc-event-dot {
  background-color: white !important;
}
.fc-event {
  border: none !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: white !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: white !important;
}

.fc {
  height: 650px !important;
  overflow-y: auto !important;
}

.fc .fc-col-header-cell-cushion {
  white-space: pre-line;
}

.highlightCurrentSelectedDate {
  background-color: lightyellow;
}

.MuiButtonGroup-groupedContainedHorizontal:not(:last-child) {
  border-right: unset !important;
}

.MuiButtonGroup-grouped {
  min-width: 30px !important;
}

#currentDateHeader {
  padding: 5px;
  width: 30px;
  background: #03acef;
  color: white;
  border-radius: 50%;
  justify-content: center;
  display: inline-flex;
}

#dateHeader {
  padding: 5px;
}

.fc-timegrid-event {
  width: 95%;
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(fonts/Lato-Bold.ttf) format('truetype');
}

#latobold {
  font-family: 'Lato-Bold';
}

#title {
  font-family: 'Lato-Bold';
  font-size: 26px;
}

@media only screen and (max-width: 600px) {
  #title {
    font-size: 20px;
  }
}

#font15 {
  font-size: 15px;
}

/* change styling for tree search */
#tree.Mui-selected {
  color: unset !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: unset !important;
}

.MuiTreeItem-content {
  align-items: start !important;
}

.MuiTreeItem-content .MuiTreeItem-label {
  background-color: unset !important;
}

#packageBullet::before {
  content: '-';
  padding-right: 40px;
}
