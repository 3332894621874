.tooltip ::after {
    content: " ";
    position: absolute;
    top: 20%;
    left: 20%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color:  transparent transparent grey transparent;
}

.MuiStepIcon-text {
    fill: white !important;
}