/* .bookedEvent {
    opacity: 1;
} */

/* calendar ui changes */

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile--active abbr {
  border: 1px solid black !important;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile--active {
  background: transparent !important;
  color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

.react-calendar__tile--now {
  background: transparent;
  color: black !important;
  text-decoration-line: underline;
}

/* For changing today's date to dot */
/* .react-calendar__tile--now abbr {
    display: inline-block;
    position: relative;
}

.react-calendar__tile--now abbr::before {
    content: '•';
    display: inline-block;
    position: absolute;
    bottom: -0.65em;
    left: 0;
    text-align: center;
    width: 100%;
    font-size: x-large;
} */

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: transparent;
}

.react-calendar__tile:disabled {
  background-color: transparent;
  color: grey;
  opacity: 0.4;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent;
  color: grey;
  opacity: 0.4;
}

.available-slot {
  /* background-color: #91DAAC !important; */
}

.forcebooking-slot abbr {
  /* border: 1px solid black !important; */
  background-color: lightgrey !important;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-choice abbr {
  background-color: #f37d2a !important;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waitinglist-slot abbr {
  background-color: lightgrey !important;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closed {
  /* background-color: grey !important; */
}

.MuiAlert-outlinedInfo {
  border: 1px solid #03acef !important;
}
